@media only screen and (min-width: 768px) {
  /* tablets and desktop */
  #player {
    min-height: 500px;
    aspect-ratio: 13/10;
  }
}

@media only screen and (max-width: 767px) {
  /* phones */
  /* .left-content {
    text-align: center;
  } */
}

/* Portrait */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  #player {
    aspect-ratio: 13/10!important;
    /* width: 100%;s */
    /* 2048x1536 */
    height: 384px;
    width: 512px;
    /* min-height: 300px; */
    /* width: 100vw; */
   min-height: 450px;
  }
  .left-content{
    /* width: 20vw */
  }
}
#player {
  aspect-ratio: 13/10;
  /* height: 100%; */
  /* width: 100%; */
  cursor: grab;
  /* max-width: 100vw; */
  /* max-height: 100vh; */
  margin: auto;
}

.player-container {
  aspect-ratio: 13/10;
}
